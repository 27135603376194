<template lang="pug">
om-modal.new-campaign-sms-prompt-modal.centered-modal(
  name="new-campaign-sms-prompt"
  :width="500"
  :clickToClose="false"
  @beforeOpen="beforeOpen"
)
  template(slot="modal-header")
    .row
      .col
        om-heading(h5) {{ $t('newCampaignSMSPrompt.title') }}
    .brand-modal-action-icon.cursor-pointer(@click="$modal.hide('new-campaign-sms-prompt')")
      close-icon(:width="12" :height="12" color="#AAB1C1")
  template(slot="modal-body")
    BoxSelect(:items="boxSelectItems" @select="createCampaign")
</template>
<script>
  import { get as _get } from 'lodash-es';
  import { getAccountIdFromCookie } from '@/util';
  import CREATE_CAMPAIGN from '@/graphql/CreateCampaign.gql';

  export default {
    components: {
      BoxSelect: () => import('@/components/Elements/BoxSelect/List.vue'),
    },
    data: () => ({
      campaignCreateInput: null,
      sourceUrl: '',
    }),

    computed: {
      boxSelectItems() {
        return [
          {
            value: 'onlyEmail',
            image: require(`@/assets/admin/svg/newCampaignSMSPrompt/email.svg`),
            title: `newCampaignSMSPrompt.email`,
          },
          {
            value: 'withSMS',
            image: require(`@/assets/admin/svg/newCampaignSMSPrompt/sms.svg`),
            title: `newCampaignSMSPrompt.emailAndSMS`,
          },
        ];
      },
    },
    methods: {
      beforeOpen(event) {
        this.sourceUrl = event.params.sourceUrl;
        this.campaignCreateInput = event.params.campaignCreateInput;
      },
      async createCampaign(type) {
        const r = await this.$apollo.mutate({
          mutation: CREATE_CAMPAIGN,
          variables: {
            input: { ...this.campaignCreateInput, removeSMSPage: type === 'onlyEmail' },
          },
        });

        const campaignId = _get(r, 'data.createCampaign.id');
        const variantId = _get(r, 'data.createCampaign.variants.0._id');

        if (!campaignId || !variantId) {
          throw new Error('Failed to create campaign');
        }

        window.location = `/${getAccountIdFromCookie()}/variant/${campaignId}/${variantId}/edit/new${
          this.sourceUrl
        }`;
      },
    },
  };
</script>

<style lang="sass">
  .new-campaign-sms-prompt-modal
    .brand-modal-header
      text-align: center
      .heading-5
        font-size: 20px !important
    .box-select-item-title
      margin-bottom: 0
      font-size: 16px
      font-weight: 500
    .box-select-item
      padding: 20px 0
    .brand-modal-body
      padding: 0 2.857rem
</style>
